<template>
  <Card2 inner-gradient="true">
    <router-link
      :to="link"
      class="level-card"
      :style="{ 'background-image': 'url(' + imageSrc + ')' }"
    >
      <h4 class="heading">
        <span class="sub-heading">{{ category }}</span>
        {{ title }}
      </h4>
      <Pill v-if="points" type="primary" class="pill"> {{ points }}pts </Pill>
      <div v-if="locked" class="locked"></div>
    </router-link>
  </Card2>
</template>

<script>
import Card2 from '@/components/global/ui/Card2.vue'
export default {
  name: 'LevelCard',
  components: {
    Card2
  },
  props: {
    category: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    routerLink: {
      type: String,
      default: ''
    },
    imageSrc: {
      type: String,
      default: null
    },
    points: {
      type: Number,
      default: null
    },
    completed: {
      type: Boolean,
      default: null
    },
    locked: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showPoints() {
      return !this.completed && this.points
    },
    showCompleted() {
      return this.completed && this.points
    },
    link() {
      return !this.locked ? this.routerLink : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.level-card {
  display: flex;
  height: 280px;
  padding: 8px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column-reverse;
  justify-content: space-between;
  cursor: pointer;
  position: relative;

  &::after {
    opacity: 0.5;
    display: block;
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    height: 50%;
    z-index: 1;
    background: linear-gradient(
      0deg,
      rgba(#333333, 1) 0%,
      rgba(#333333, 0) 100%
    );
  }

  .locked {
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    position: absolute;
    z-index: 2;
    background-color: rgba(#ebebeb, 0.7);

    &::before {
      content: '';
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      background-image: url('~@/assets/images/padlock.svg');
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}

.heading {
  font-size: 3.2rem;
  line-height: 2.8rem;
  font-weight: 600;
  margin: 0;
  color: #ffffff;
  padding: 16px 8px;
  position: relative;
  z-index: 2;

  .sub-heading {
    display: block;
    font-size: 1.4rem;
    font-weight: 600;
  }
}

.pill {
  align-self: flex-end;
  min-width: 48px;
  width: auto;
}
</style>
