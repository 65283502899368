<template>
  <div v-if="show" id="container" class="container">
    <div class="close" @click="closeModal">
      <i class="fal fa-times" />
      <span class="u__visually-hidden">Close</span>
    </div>
    <div class="mask"></div>
    <div id="confetti" class="confetti-animation"></div>
    <div class="wrapper">
      <div id="content" class="animation-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap, Power3, Sine, Linear } from 'gsap'

export default {
  props: {
    show: Boolean
  },
  data: function() {
    return {}
  },
  watch: {
    show: {
      immediate: true,
      handler() {
        if (this.show) {
          setTimeout(() => {
            this.init()
          }, 1)
        }
      }
    }
  },
  mounted() {
    this.numConfetti = 150
    this.winWidth = window.screen.width
    this.winHeight = window.screen.height
    this.palette = ['#e3a46d', '#85cba0', '#a98ff0', '#c8e3aa']
  },
  methods: {
    random(min, max) {
      return min + Math.floor(Math.random() * (max - min))
    },
    closeModal() {
      gsap.to(this.appContainer, {
        duration: 0.3,
        opacity: 0,
        onComplete: () => {
          this.$emit('closeModal', false)
        }
      })
    },
    animateConfetti(el) {
      const delay = this.random(9, 12) / 10
      const duration = this.random(9, 11) / 10

      gsap.to(el, {
        delay,
        duration,
        y: this.random(-500, this.winHeight - 200),
        ease: Power3.easeOut
      })

      gsap.to(el, {
        delay: delay + duration,
        duration: this.random(7, 9),
        y: `random(${this.winHeight}, ${this.winHeight + 200})`,
        ease: Linear.easeNone,
        onComplete: () => {
          el.remove()
        }
      })

      gsap.to(el, {
        duration: 'random(1, 5)',
        x: `+=50`,
        repeat: -1,
        yoyo: true,
        ease: Sine.easeInOut
      })

      gsap.to(el, {
        duration: 'random(1,6)',
        scaleX: 0.2,
        rotationX: 'random(0, 360)',
        rotationY: 'random(0, 360)',
        rotationZ: 'random(0, 360)',
        repeat: -1,
        yoyo: true,
        ease: Sine.easeInOut
      })

      gsap.to(el, {
        duration: 'random(2, 5)',
        opacity: 0.5,
        repeat: -1,
        yoyo: true,
        ease: Sine.easeInOut
      })
    },
    init() {
      this.appContainer = document.querySelector('#container')
      this.confettiContainer = document.querySelector('#confetti')
      this.contentContainer = document.querySelector('#content')

      gsap.to(this.appContainer, {
        delay: 0,
        duration: 1,
        opacity: 1
      })

      gsap.set(this.contentContainer, {
        y: 150
      })

      gsap.to(this.contentContainer, {
        delay: 0.9,
        y: 0,
        opacity: 1
      })

      for (let i = 0; i < this.numConfetti; i++) {
        let confetto = document.createElement('div')
        confetto.classList.add('confetto')
        this.confettiContainer.appendChild(confetto)

        gsap.set(confetto, {
          transformStyle: 'preserve-3d',
          x: this.random(-15, this.winWidth),
          y: this.random(this.winHeight, this.winHeight + 100),
          opacity: 1,
          scale: 'random(0.5, 1.1)',
          backgroundColor: this.palette[this.random(0, 3)]
        })

        setTimeout(() => {
          this.animateConfetti(confetto)
        }, 10)
      }
    }
  }
}
</script>

<style lang="scss">
.container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  opacity: 0;
  background-color: #f7f8f3;
}

.confetti-animation {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.wrapper {
  position: relative;
  margin: auto 0;
  z-index: 2;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 36px;
  height: 36px;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  z-index: 3;
}

.mask {
  background: linear-gradient(0, #f7f8f3, rgba(255, 255, 255, 0));
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.animation-content {
  text-align: center;
  margin: auto;
  opacity: 0;
}

.confetto {
  width: 14px;
  height: 20px;
  position: absolute;
  background-color: #00ff00;
  z-index: 2;
}
</style>
