<template>
  <div class="page">
    <div
      class="page-header-with-title"
      :style="{ backgroundImage: `url(${journey.headerImagePath})` }"
    >
      <h1>
        {{ journey.name }} <span>{{ journey.subtitle }}</span>
      </h1>
    </div>
    <ConfettiAnimation :show="showSuccess" @closeModal="closedConfetti = true">
      <img src="~@/assets/images/journeys/complete.svg" class="success__icon" />
      <h2 class="success__heading">
        Journey Complete!
      </h2>
      <p class="success__points">
        You earned <Pill type="primary">{{ getPointsAvailable }}pts!</Pill>
      </p>
      <p class="success__text">
        Check your email for details of<br />
        how to redeem your points
      </p>
    </ConfettiAnimation>
    <Content class="summary__intro">
      <h2 class="challenges__heading">Your hybrid working impact</h2>
      <p>
        <img src="~@/assets/images/journeys/graph.png" class="summary__graph" />
      </p>
      <p>
        Thank you for working with us to reduce our impact. So far, you’ve
        helped us create an emissions snapshot, contributed ideas and hopefully
        learned something along the way. We will notify you regarding your tax
        relief and you can use your points with our incentive scheme.
      </p>
      <p>
        <strong>So what now?</strong> Join a team challenge below and you can
        earn more points, reduce your emissions and access some great offers.
        You can also submit new ideas at the bottom of this page.
      </p>
    </Content>
    <Content
      v-if="activeChallenges.length > 0"
      background="#F7F8F3"
      class="challenge challenge--active"
    >
      <h2 class="challenges__heading">Active challenges</h2>
      <LevelCard
        v-for="challenge in activeChallenges"
        :key="challenge.id"
        :category="challenge.type"
        :title="challenge.name"
        :image-src="challenge.cardImagePath"
        :completed="challenge.hasUserCompleted"
        :router-link="'/challenges/' + challenge.id"
      />
    </Content>
    <Content
      v-if="notStartedChallenges.length > 0"
      class="challenge challenge--notstarted"
    >
      <h2 class="challenges__heading">Join a challenge</h2>
      <LevelCard
        v-for="challenge in notStartedChallenges"
        :key="challenge.id"
        :category="challenge.type"
        :title="challenge.name"
        :image-src="challenge.cardImagePath"
        :completed="challenge.hasUserCompleted"
        :router-link="'/challenges/' + challenge.id"
      />
    </Content>
    <Content
      v-if="completedChallenges.length > 0"
      background="#F7F8F3"
      class="confetti-background challenge challenge--completed"
    >
      <h2 class="challenges__heading">Completed challenges</h2>
      <LevelCard
        v-for="challenge in completedChallenges"
        :key="challenge.id"
        :category="challenge.type"
        :title="challenge.name"
        :image-src="challenge.cardImagePath"
        :completed="challenge.hasUserCompleted"
        :router-link="'/challenges/' + challenge.id"
      />
    </Content>
    <Content class="search">
      <h2 class="challenges__heading">Submit an idea</h2>
      <IdeaSubmit />
    </Content>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import IdeaSubmit from '@/components/global/IdeaSubmit.vue'
import LevelCard from '@/components/global/ui/cards/LevelCard.vue'
import ConfettiAnimation from '@/components/ConfettiAnimation.vue'

export default {
  components: {
    LevelCard,
    IdeaSubmit,
    ConfettiAnimation
  },
  data: function() {
    return {
      journeyId: null,
      closedConfetti: false
    }
  },
  computed: {
    ...mapGetters({
      journey: 'journey/getJourney',
      getNextLevel: 'levels/getNextLevel',
      activeChallengesGetter: 'challenges/getActiveChallengesForJourney',
      getPointsAvailable: 'journey/getPointsAvailable',
      notStartedChallengesGetter:
        'challenges/getNotStartedChallengesForJourney',
      completedChallengesGetter: 'challenges/getCompleteChallengesForJourney'
    }),
    activeChallenges() {
      return this.activeChallengesGetter(this.journey.challenges)
    },
    notStartedChallenges() {
      return this.notStartedChallengesGetter(this.journey.challenges)
    },
    completedChallenges() {
      return this.completedChallengesGetter(this.journey.challenges)
    },
    showSuccess() {
      return this.$route.query.confetti && !this.closedConfetti
    }
  },
  async mounted() {
    this.journeyId = this.$route.params.journeyId

    await this.$store.dispatch('levels/openDBChannel', {
      pathVariables: {
        journeyId: this.journeyId
      },
      clauses: {
        orderBy: ['order']
      }
    })

    await this.$store.dispatch('challenges/openDBChannel')

    await this.$store.dispatch('journey/openDBChannel', {
      pathVariables: {
        journeyId: this.journeyId
      }
    })

    if (this.getNextLevel) {
      this.$router.replace('levels')
      return
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/components/page-header';

.challenge--notstarted {
  padding-top: 0 !important;
}

.challenge--active + .challenge--notstarted {
  padding-top: 2.4rem !important;
}

> .content {
  padding-bottom: 3.6rem !important;
}

.content .card:last-child {
  margin-bottom: 0;
}

.page {
  position: relative;

  @include respond-above(sm) {
    margin-top: 60px;
  }
}

.challenges__heading {
  font-size: 2.4rem;
  font-weight: 400;
  color: #4a4a4a;
  margin: 1.6rem 0;
}

.success {
  @extend .confetti-background;
  text-align: center;
  background-color: #f7f8f3;
  position: absolute;
  box-shadow: 0 2px 6px rgba(#000000, 0.32);
  padding: 5.3rem 1.5rem 2.8rem;
  width: 100%;
  opacity: 0;
  transition: 0.25s ease all;

  &.open {
    top: 0 !important;
    opacity: 1;
  }

  &__icon {
    color: $color-gray--dark;
    font-size: 8rem;
    margin-bottom: $spacing-m;
  }

  &__heading {
    font-size: 3rem;
    color: $color-gray--dark;
    font-weight: 600;
  }

  &__points {
    color: #54c6cc;
    font-size: 1.8rem;
    font-weight: 500;
  }

  &__text {
    font-size: 1.6rem;
    color: $color-gray--dark;
  }
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  outline: none;
  border: none;
  font-size: 23px;
  height: 44px;
  width: 44px;
}

.summary__intro {
  padding-top: 3.2rem !important;
  padding-bottom: 1rem !important;
}

.summary__graph {
  max-width: 100%;
  width: auto;
}
</style>
